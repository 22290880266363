/**
 * This middleware is used to prevent the re-routing with query parameters on pages that are cached.
 *
 * Since we're ignoring query params when caching entire pages, script tags that are in the document will
 * be included in the cached response. When the Nuxt app hydrates, it will trigger a middleware to sync the URL
 * with the documents contents. This causes users to be unexpectedly redirected to a page with (other) query parameters,
 * leading to incorrect attribution in utm / marketing campaigns.
 *
 * In these scenarios we want to abort the navigation to prevent that from happening.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  try {
    const fromSlug =
      typeof from.params?.slug === "string"
        ? from.params?.slug
        : from.params?.slug?.join("/");
    const toSlug =
      typeof to.params?.slug === "string"
        ? to.params?.slug
        : to.params?.slug?.join("/");

    /**
     * In this situation, the user is visting the same page, but the query params have changed. This indicates
     * that the params are overwritten by a cache match. In this case, we want to abort the re-routing to cached
     * query parameters, so marketing can effectively measure the effectiveness of campaigns.
     *
     * We also need to check if there are encoded characters in the path. If that's the case, we need to let Nuxt handle
     * the decoding of the query parameters and therefore we cannot abort the navigation.
     *
     * On a final note: routes with hashes in them should also be ignored from aborting the navigation.
     */
    if (
      !to.hash &&
      from.fullPath === decodeURIComponent(from.fullPath) &&
      from.fullPath !== to.fullPath &&
      fromSlug === toSlug
    ) {
      return abortNavigation();
    }
  } catch (error) {
    throw new Error("Failed to execute content canvas middleware: " + error);
  }
});
